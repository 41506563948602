import React, { lazy, Suspense } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { ROUTES } from '../config/routes';

const HomePage = lazy(() => import('./HomePage'));
const LoginPage = lazy(() => import('./LoginPage'));
const InGame = lazy(() => import('./InGame'));
const Admin = lazy(() => import('./Admin'));
const LoadingPage = lazy(() => import('./LoadingPage'))
const SelectGame = lazy(() => import('./SelectGame'))
const Loading = () => (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'transparent' }} open={true} >
        <CircularProgress color="primary" />
    </Backdrop>
);
export const RootRoutes = () => {
    return (
        <Suspense fallback={<Loading />}>
            <Router>
                <Routes>
                    <Route
                        path={ROUTES.home}
                        element={
                            <HomePage />
                        }
                    />
                    <Route
                        path={ROUTES.login}
                        element={
                            <LoginPage />
                        }
                    />
                    <Route
                        path={ROUTES.inGame}
                        element={
                            <InGame />
                        }
                    />
                    <Route
                        path={ROUTES.admin}
                        element={
                            <Admin />
                        }
                    />
                    <Route
                        path={ROUTES.loading}
                        element={
                            <LoadingPage />
                        }
                    />
                    <Route
                        path={ROUTES.selectGame}
                        element={
                            <SelectGame />
                        }
                    />


                </Routes>
            </Router>
        </Suspense >
    );
};
